import i18n from '@/plugins/i18n'
const {
  global: { t },
} = i18n

export const dashboardTableHeaders = [
  {
    field: 'type',
    label: t('DASHBOARD_TABLE_HEADER_TYPE'),
  },
  {
    field: 'timeStamp',
    label: t('DASHBOARD_TABLE_HEADER_TIME_STAMP'),
  },
  {
    field: 'client',
    label: t('DASHBOARD_TABLE_HEADER_CLIENT'),
  },
  {
    field: 'residence',
    label: t('DASHBOARD_TABLE_HEADER_RESIDENCE'),
  },
  {
    field: 'building',
    label: t('DASHBOARD_TABLE_HEADER_BUILDING'),
  },
  {
    field: 'link',
    label: t('DASHBOARD_TABLE_HEADER_LINK'),
  },
  {
    field: 'status',
    label: t('DASHBOARD_TABLE_HEADER_STATUS'),
  },
  {
    field: 'stateSensor',
    label: t('DASHBOARD_TABLE_HEADER_STATE_SENSOR'),
  },
  {
    field: 'resolutionStamp',
    label: t('DASHBOARD_TABLE_HEADER_RESOLUTION_STAMP'),
  },
  {
    field: 'osNumber',
    label: t('DASHBOARD_TABLE_HEADER_NUMBER'),
  },
  {
    field: 'download',
    label: 'DownloadIcon',
  },
]

// Type d’objet Client
// Résidence
// Bâtiment
// Étage
// Cage d’escalier
// Code Postal et/ou Ville
// Statut
// Etat temps-réel appareillage 
// Piles

export const monitoringTableHeaders = [
  {
    field: 'Download',
    label: 'DownloadIcon',
  },
  {
    field: 'Type',
    label: t('MONITORING_TABLE_HEADER_TYPE'),
  },
  {
    field: 'Synchronization',
    label: t('MONITORING_TABLE_HEADER_SYNCHRONIZATION'),
  },
  {
    field: 'Client',
    label: t('DASHBOARD_TABLE_HEADER_CLIENT'),
  },
  {
    field: 'Residence',
    label: t('DASHBOARD_TABLE_HEADER_RESIDENCE'),
  },
  {
    field: 'Building',
    label: t('DASHBOARD_TABLE_HEADER_BUILDING'),
  },
  {
    field: 'Floor',
    label: t('MONITORING_TABLE_HEADER_FLOOR'),
  },
  {
    field: 'Stairwell',
    label: t('MONITORING_TABLE_HEADER_STAIRWELL'),
  },
  {
    field: 'Town',
    label: t('MONITORING_TABLE_HEADER_TOWN'),
  },
  {
    field: 'Status',
    label: t('DASHBOARD_TABLE_HEADER_STATUS'),
  },
  {
    field: 'RealTimeState',
    label: t('MONITORING_TABLE_HEADER_REALTIMESTATE'),
  },
  {
    field: 'Battery',
    label: t('MONITORING_TABLE_HEADER_BATTERY'),
  },
]

export const realEstateTableHeaders = [
  {
    field: 'companyName',
    label: t('REAL_ESTATE_TABLE_HEADER_CLIENT'),
  },
  {
    field: 'parentName',
    label: t('REAL_ESTATE_TABLE_HEADER_RESIDENCE'),
  },
  {
    field: 'build',
    label: t('REAL_ESTATE_TABLE_HEADER_BUILD'),
  },
  {
    field: 'zipCode',
    label: t('REAL_ESTATE_TABLE_HEADER_POSTAL_CODE'),
  },
  {
    field: 'city',
    label: t('REAL_ESTATE_TABLE_HEADER_POSTAL_CITY'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const usersTableHeaders = [
  {
    field: 'lastname',
    label: t('USER_SETTINGS_TABLE_HEADER_SURNAME'),
  },
  {
    field: 'firstname',
    label: t('USER_SETTINGS_TABLE_HEADER_NAME'),
  },
  {
    field: 'email',
    label: t('USER_SETTINGS_TABLE_HEADER_EMAIL'),
  },
  {
    field: 'phoneNumber',
    label: t('USER_SETTINGS_TABLE_HEADER_PHONE'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const activityTableHeaders = [
  {
    field: 'client',
    label: t('ACTIVITY_TABLE_HEADER_CLIENT'),
  },
  {
    field: 'residence',
    label: t('ACTIVITY_TABLE_HEADER_RESIDENCE'),
  },
  {
    field: 'town',
    label: t('ACTIVITY_TABLE_HEADER_TOWN'),
  },
  {
    field: 'build',
    label: t('ACTIVITY_TABLE_HEADER_BUILD'),
  },
  {
    field: 'floor',
    label: t('ACTIVITY_TABLE_HEADER_FLOOR'),
  },
  {
    field: 'object',
    label: t('ACTIVITY_TABLE_HEADER_OBJECT'),
  },
  {
    field: 'isUnderContract',
    label: t('ACTIVITY_TABLE_HEADER_UNDER_CONTRACT'),
  },
  {
    field: 'isLocallyVerified',
    label: t('ACTIVITY_TABLE_HEADER_LOCALLY_VERIFIED'),
  },
  {
    field: 'isOsNumberObligatory',
    label: t('ACTIVITY_TABLE_HEADER_OS_NUMBER_OBLIGATORY'),
  },
  {
    field: 'timeBeforeIntervention',
    label: t('ACTIVITY_TABLE_HEADER_TIME_BEFORE_INTERVENTION'),
  },
  {
    field: 'hasInfoForManager',
    label: t('ACTIVITY_TABLE_HEADER_INFO_FOR_MANAGER'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const guardianTableHeaders = [
  {
    field: 'lastname',
    label: t('CONTACT_TABLE_HEADER_LASTNAME'),
  },
  {
    field: 'firstname',
    label: t('CONTACT_TABLE_HEADER_FIRSTNAME'),
  },
  {
    field: 'email',
    label: t('CONTACT_TABLE_HEADER_EMAIL'),
  },
  {
    field: 'phoneNumber',
    label: t('CONTACT_TABLE_HEADER_PHONE'),
  },
  {
    field: 'createdAt',
    label: t('CONTACT_TABLE_HEADER_ADD_DATE'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const adminTableHeaders = [
  {
    field: 'companyName',
    label: t('CONTACT_TABLE_HEADER_LESSOR'),
  },
  {
    field: 'lastname',
    label: t('CONTACT_TABLE_HEADER_LASTNAME'),
  },
  {
    field: 'firstname',
    label: t('CONTACT_TABLE_HEADER_FIRSTNAME'),
  },
  {
    field: 'email',
    label: t('CONTACT_TABLE_HEADER_EMAIL'),
  },
  {
    field: 'phoneNumber',
    label: t('CONTACT_TABLE_HEADER_PHONE'),
  },
  {
    field: 'createdAt',
    label: t('CONTACT_TABLE_HEADER_ADD_DATE'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const organismTableHeaders = [
  {
    field: 'name',
    label: t('CONTACT_TABLE_HEADER_LASTNAME'),
  },
  {
    field: 'createdAt',
    label: t('CONTACT_TABLE_HEADER_ADD_DATE'),
  },
  {
    field: 'details',
    label: '',
  },
]
export const companyTableHeaders = [
  {
    field: 'name',
    label: t('CONTACT_TABLE_HEADER_LASTNAME'),
  },
  {
    field: 'createdAt',
    label: t('CONTACT_TABLE_HEADER_ADD_DATE'),
  },
  {
    field: 'details',
    label: '',
  },
]

export const techTableHeaders = [
  {
    field: 'companyName',
    label: t('CONTACT_TABLE_HEADER_LESSOR'),
  },
  {
    field: 'lastname',
    label: t('CONTACT_TABLE_HEADER_LASTNAME'),
  },
  {
    field: 'firstname',
    label: t('CONTACT_TABLE_HEADER_FIRSTNAME'),
  },
  {
    field: 'email',
    label: t('CONTACT_TABLE_HEADER_EMAIL'),
  },
  {
    field: 'phoneNumber',
    label: t('CONTACT_TABLE_HEADER_PHONE'),
  },
  {
    field: 'createdAt',
    label: t('CONTACT_TABLE_HEADER_ADD_DATE'),
  }
]
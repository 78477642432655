import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-1 flex items-center justify-between bg-lightGrey rounded-sm overflow-hidden" }
const _hoisted_2 = { class: "px-5 py-6" }
const _hoisted_3 = { class: "text-base font-medium" }
const _hoisted_4 = { class: "text-center text-lg font-medium text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.counterText), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-center justify-center h-full min-w-[80px]", _ctx.counterColor])
    }, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.counterNumber), 1)
    ], 2)
  ]))
}

import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    counterText: {
      type: String,
      default: '',
    },
    counterNumber: {
      type: String,
      default: '',
    },
    counterColor: {
      type: String,
      default: '',
    },
  },
})
